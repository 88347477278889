import * as React from 'react';
import parse from 'html-react-parser';
import styled from 'styled-components';

interface IManualContentProps {
    content: string;
    title: string;
}
class ManualContent extends React.Component<IManualContentProps, any> {
    private readonly content: string;
    private readonly title: string;
    constructor(props: IManualContentProps) {
        super(props);

        this.content = props.content;
        this.title = props.title;
    }

    public render(): React.ReactNode {

        return (
            <ContentBox>
                <ContentBoxHeader>
                    {this.title}
                </ContentBoxHeader>
                <ContentBoxContent>
                    {parse(this.content)}
                </ContentBoxContent>
            </ContentBox>
        );
    }
}

const ContentBox = styled('section')(() => ({
}));
const ContentBoxHeader = styled('div')(() => ({
    padding: '0 16px',
    height: '64px',
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: '500',
    borderBottom: '1px solid #39917b',
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'center',
}));
const ContentBoxContent = styled('div')(() => ({
    padding: '16px',
}));

export {ManualContent};
